import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
{
    path: '/default',
    component: () => import('../views/layoutDefault.vue'),  // layout for most page
    children: [
    {
        path: '/sresult',
        name: 'sresult',
        component: () => import('../views/sresult.vue'), 
        props: (route) => ({ searchData: route.query.searchData }),
        meta: { layout: 'default' } 
    },
    {
        path: '/genomeInfo',
        name: 'genomeInfo',
        component: () => import('../views/genomeInfo.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/FamilyList',
        name: 'FamilyList',
        component: () => import('../views/FamilyList.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/GeneInfo',
        name: 'GeneInfo',
        component: () => import('../views/GeneInfo.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/Transcript',
        name: 'Transcript',
        component: () => import('../views/Transcript.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/news',
        name: 'news',
        component: () => import('../views/news.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/GenomeList',
        name: 'GenomeList',
        component: () => import('../views/GenomeList.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/submitgenome',
        name: 'submitgenome',
        component: () => import('../views/submit.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/Batchq',
        name: 'Batchq',
        component: () => import('../views/Batchq.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/goenrich',
        name: 'goenrich',
        component: () => import('../views/go_homepage.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/goresult',
        name: 'goresult',
        component: () => import('../views/goresult.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/pathwayenrich',
        name: 'pathwayenrich',
        component: () => import('../views/pathway_homePage.vue'), 
        meta: { layout: 'default' } 
    },{
        path: '/pathwayResult/:types/:uid',
        name: 'pathwayResult',
        component: () => import('../views/pathwayResult.vue'), 
        meta: { layout: 'default' } 
    }, {
        path: '/synteny',
        name: 'synteny',
        component: () => import('../views/synteny.vue'), 
        meta: { layout: 'default' } 
    },{
        path: '/syntenyResult/:genomes/:blockname',
        name: 'syntenyResult',
        component: () => import('../views/syntenyResult.vue'), 
        meta: { layout: 'default' } 
    },{
        path: '/syntenyBlock/:genomes/:blockname',
        name: 'syntenyBlock',
        component: () => import('../views/syntenyBlock.vue'), 
        meta: { layout: 'default' } 
    },{
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact.vue'), 
        meta: { layout: 'default' } 
    }, { 
        path: '/faq', 
        name: 'faq',
        component: () => import('../views/faq.vue'), 
        meta: { layout: 'default' } 
    }, { 
        path: '/manual', 
        name: 'manual',
        component: () => import('../views/manual.vue'), 
        meta: { layout: 'default' } 
    },
    ]
  },
  {
    path: '/special',
    component: () => import('../views/layoutIndex.vue'),  // layout just for index
    children: [
        {
            path: '/',
            name: 'home',
            component: HomeView, 
            meta: { layout: 'special' } 
        },
    ]
  },


]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})

export default router