<template>
    <div class="home">
        <div class="title" style="margin-top: 4vh">
            <h1>Searching Horticultural Genomic Data</h1>
        </div>

        <div class="title">
            <!-- <el-autocomplete
                v-model="searchKey"
                filterable
                remote
                reserve-keyword
                placeholder="Enter keyword"
                :loading="loading"
                :fetch-suggestions="remoteMethod"
                value-key="name"
                :trigger-on-focus="false"
                :min-chars="3"
                class="search-common"
                popper-class="el-autocomplete-suggestion"
                :popper-append-to-body="false"
            ></el-autocomplete>-->

            <el-autocomplete
                v-model="searchKey"
                :fetch-suggestions="remoteMethod"
                placeholder="Enter keyword"
                :loading="loading"
                size="large"
                  style="width: 50%; margin-top: 40px"
            />
            <el-button
                @click="goSsearch"
                style="margin-top: 40px; height: 40px"
                :icon="Search"
            />
        </div>

        <br>
        <el-row type="flex" justify="center">
            <el-col :span="12">
                <el-icon><SuccessFilled /></el-icon> <router-link to="/faq" target="_blank">Please cite HSE!!!</router-link> 
                &nbsp;&nbsp;&nbsp;
                <el-icon><SuccessFilled /></el-icon> <router-link to="/manual" target="_blank">Manual</router-link>
                &nbsp;&nbsp;&nbsp;
                <el-icon><SuccessFilled /></el-icon> <a href="http://hort.moilab.net:8888" target="_blank">Old Version</a>
            </el-col>
        </el-row>

        <br>
        <el-row type="flex" justify="center"> 
            <el-col :span="14">
                <ul>
                    <li
                        v-for="(item, index) in news"
                        :key="item.date"
                    >
                        <p class="news-text">
                            <div v-html="item.description + ' [' + item.date + ']'"></div>
                        </p>
                    </li>
                </ul>
                <p class="right-align">
                    <router-link to="/news" target="_blank">more news...</router-link>
                </p>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from "vue";
import { useRoute, onBeforeRouteUpdate, useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
import { Search } from "@element-plus/icons-vue";
import { ElAutocomplete, ElButton } from "element-plus";

const options = ref([]);
const searchKey = ref('');
const loading = ref(false);
const router = useRouter();

const news = ref(null);

const fetchData = () => {
    fetch("news.json")
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((jsonData) => {
            news.value = jsonData.announcements.slice(0, 5);
        })
        .catch((error) => {
            console.error(
                "There has been a problem with your fetch operation:",
                error
            );
        });
};

onMounted(() => {
    fetchData();
});

const remoteMethod = (query, cb) => {
    if (query) {
        loading.value = true;
        proxy.util.post(
            proxy.api.searchData,
            {
                keyword: query,
            },
            (resp) => {
                // console.log(resp, 'prompt searching key words');
                loading.value = false;
                let data = [];
                resp.forEach((element) => {
                    data.push({
                        value: element.name,
                        link: element.name,
                    });
                });
                cb(data);
            },
            (err) => {
                loading.value = false;
                // console.log(err);
            }
        );
        // setTimeout(() => {
        //     loading.value = false;
        //     options.value = list.value.filter((item) => {
        //         return item.label.toLowerCase().includes(query.toLowerCase());
        //     });
        // }, 200);
    } else {
        options.value = [];
    }
};

const goSsearch = () => {
    // console.log('searching key',searchKey.value);
    router.push({ path: '/sresult', query: { keyword: searchKey.value } });
};

</script>
<style lang="less" scoped>
li {
    list-style-type: "♦";
    margin-left: 50;
}
.news-text {
    padding: 2px;
}

.right-align {
    text-align: right;
}

.home {
    height: 100px;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            font-size: 36px;
        }
    }
    .title1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .textadv {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-top: 20px;
    }
    .textWraper {
        width: 50%;
        // display: inherit;
        line-height: 1.2;
        align-items: center;
        margin-top: 5px;
        i {
            margin-right: 5px;
        }
    }
}
</style>
