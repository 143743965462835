import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'


export default createStore({
    state: {
        init: 'intVal',
        navconfigData: "",
        searchResults: null,
    },
    getters: {
        searchResults: (state) => state.searchResults
    },
    mutations: {
        setSearchResults(state, results) {
            state.searchResults = results;
        }
    },
    actions: {
        updateSearchResults({ commit }, results) {
            commit('setSearchResults', results);
        }
    },
    modules: {},
    plugins: [createPersistedstate({
        reducer(val) {
            //console.log(val, "valvalvalvalvalval");
            return {
                // 只储存state中的assessmentData
                //  assessmentData: val.assessmentData
                // fileSwithLayout: val.fileSwithLayout,
                // navSwithLayout: val.navSwithLayout,
                // proNavSwitchLayout: val.proNavSwitchLayout,
                // eventTargrt: val.eventTargrt, //full   switch
                // taskInfo: val.taskInfo,
                // logoInfo: val.logoInfo,
                // projectInfo: val.projectInfo,
                // nameTree: val.nameTree,
                navconfigData: val.navconfigData,
            }
        }
    })]
})