import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./assets/css/style.css"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// window.ResizeObserver = undefined
// import "./assets/css/bootstrap.css"
import api from "./utils/api"

import util from './utils/util'


const app = createApp(App)

// app.config.globalProperties.api = api
// app.use(util)
// app.use(api)
// app.provide('$util', util)
// 使用自定义字符串方式
// app.provide('$Test', test)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus).use(store).use(router).mount('#app')
app.config.globalProperties.util = util
app.config.globalProperties.api = api