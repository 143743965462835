import axios from 'axios'
console.log(process.env, "process.envprocess.env");

// export const BASE_URL = process.env.NODE_ENV === 'production' ? window.location.origin : process.env.VUE_APP_URL
export const BASE_URL = process.env.NODE_ENV === 'production' ? "http://36.155.107.117:8080" : "http://36.155.107.117:8082"
// test API 
// export const BASE_URL = "http://36.155.107.117:8080"

export const http = (type, url, data, success, error) => {
    console.log();

    let config = {
        method: type,
        url: BASE_URL + url,
        withCredentials: true,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "",
            'Content-Type': "application/json; charset=utf-8"
        }
    }

    if (type === 'post' || type === 'put') {
        config.data = (data)
    } else if (type === 'delete' || type === 'patch') {
        config.data = data
    } else if (type === 'get') {
        config.params = data
    }
    axios(config).then((response) => {
        const data = response.data.data
        const code = response.data.code
        const message = response.data.msg || ''
        let count = data.count
        if (code == 200 && data != '') {
            typeof success === 'function' && success(data)
        } else {
            typeof error === 'function' && error(message)
        }

        // if (code === 200) {
        //     typeof success === 'function' && success(data)
        // } else if (code === 4030) {

        // } else if (code === 402) { } else if (code === 600) { } else {
        //     typeof error === 'function' && error(message)
        // }
    }).catch(function (err) {
        console.log(err)
        typeof error === 'function' && error(message)
    })
}

/**
 * 发送post请求
 * @param url
 * @param params
 * @param success
 * @param error
 */
export const post = (url, params, success, error) => {
    // let pro_id = sessionStorage.getItem('pro_id')
    // params.pro_id = pro_id * 1

    http('post', url, params, success, error)
}

export const get = (url, params, success, error) => {
    http('get', url, { pro_id: sessionStorage.getItem('pro_id') * 1, ...params }, success, error)
}
export const delet = (url, params, success, error) => {
    http('delete', url, params, success, error)
}
export const update = (url, params, success, error) => {
    http('patch', url, params, success, error)
}

export const put = (url, params, success, error) => {
    http('put', url, params, success, error)
}