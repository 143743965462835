<template>
    <router-view />
</template>
<script>
import { ref, watch } from "vue";
import { useRoute, onBeforeRouteUpdate, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
let userId = ref();
let activePath = ref();

export default {
    name: 'HSE',
    data() {
        return {
            activeIndex: "1",
            options: [
                {
                    value: "Option1",
                    label: "Option1",
                },
            ],
        };
    },
    setup() {
        const router = useRouter();

        const handleSelect = (res) => {
            // console.log(process.env.BASE_URL);
            // router.push(res);
            // console.log(router, "routerrouter");
        };
        // this._router = router;
        const goNextPage = (res) => {
            console.log("1111");

            console.log(router, "routerrouter");
            const url = router.resolve({
                name: res,
            });
            window.open(url.href, "_blank");
        };
        const openOutLink = (res) => {
            window.open(res, "_blank");
        };
        const gohome = () => {
            router.push("/");
        };

        return {
            handleSelect,
            goNextPage,
            gohome,
            openOutLink,
        };
    },
    methods: {
        // handleSelect(key, keyPath) {
        //     console.log(key, keyPath);
        //     console.log(router, "routerrouter");
        //     // const url = router.resolve({
        //     //     name: "record",
        //     //     params: { id: row.id },
        //     // });
        //     // window.open(url.href, "_blank");
        // },
    },
};
</script>
<!---<style src="@/assets/css/style.css" lang="css">
</style>-->

<style lang="less" scope>
/* style for header and footer */
.common-layout {
    .Header {
        background: var(--primary-color);
        .el-menu--horizontal {
            border: none;
        }
    }
    .mainContainer {
        min-height: calc(100vh - 215px);
    }
    .popperClass {
        background: #3d3333;
    }
    .el-menu--horizontal {
        background: none;
    }
  
    .el-menu-item {
        background: none !important;
    }
    /deep/ .el-menu--horizontal .el-sub-menu .el-sub-menu__title {
        background: none !important;
        color: #c72222 !important;
       
    }
    /deep/ .el-menu--horizontal .el-sub-menu .el-sub-menu__title:hover {
        background: #ba1313 !important;
        color: var(--primary-color) !important;
    }
}
</style>